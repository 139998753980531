<template>
	<div class="Sketchtodescribe">
    <div class="countdown">
        <div class="clearfix" style="width:100%">
          <div class="div_titles fl">草图描述</div>
          <div class="count_span fl" v-show="countNum==1">
              <span>{{count.name}}倒计时：</span>
              <span class="timer_s">{{timer}}</span>
          </div>
          <button class="count_but fr" @click="subnits()" v-show="shownull==true">保存</button>
          <button class="count_but fr" @click="submit()" v-show="shownull==false && searchVal<8">提交</button>
          <button class="count_but zdy_but fr" style="margin-right:20px" @click="emptys()" v-show="shownull==false && searchVal<8">重新绘制</button>
          <div class="fr ipload_div" v-show="shownull==true">
            <el-upload
              class="upload-demo"
              :action="this.$store.state.uploads"
              accept=".jpg, .jpeg, .png,"
              :show-file-list="false"
              :on-success="handsuccessForm"
              :before-upload="beforeAvatarUpload"
              :limit="1"
              :file-list="fileList">
              <el-button size="mini" type="primary" class="zdy_but">自定义上传</el-button>
            </el-upload>
          </div>
    </div>
    </div>
<div class="Sketch_div">
    <div class="sket_div" v-show="shownull==false">
      <ul class="sket_div_ul clearfix">
        <li class="fl" v-for="(item,index) in srcList" :key="index">
          <span class="li_span_img">
            <i class="el-icon-zoom-in" @click="dk_Dt(item)"></i>
          </span>
          <el-image :src="item.value" fit="scale-down" ></el-image>
          <div class="demo_span">{{item.userName}}</div>
        </li>
      </ul>
    </div>
		<div class="topology" v-show="shownull==true">
			<div class="tools">
        <div class="div_tooles">
          <div v-for="(item, index) in Tools" :key="index" >
            <div class="title">{{ item.group }}</div>
            <div class="buttons">
              <a
                v-for="(btn, i) in item.children"
                :key="i"
                :title="btn.name"
                :draggable="btn.data"
                @dragstart="onDrag($event, btn)"
              >
                <i :class="`iconfont ${btn.icon}`"></i>
              </a>
            </div>
          </div>
        </div>
			</div>
			<!-- 画布 -->
			<div id="topology" class="full" :class="TopologyData.grid?'canvas-container':''" @contextmenu="onContextMenu($event)"></div>
		</div>
        </div>
        <div class="caotu_popup">
          <el-dialog
                title="查看大图"
                :visible.sync="dialogVisible"
                width="800px"
                :before-close="handleClose"
                :show-close="false"
                top="10vh"
          >
          <i class="el-icon-close closei" @click="closei()"></i>
          <div class="caotu_img">
            <img :src="imgUrl"  alt="">
          </div>
          </el-dialog>
        </div>
	</div>
</template>
<script>
// 1. 导入绘画引擎
import { Topology } from '@topology/core';
import { canvasRegister } from './../../static/canvas.js'
import { Tools } from './../../static/canvas.js'
import './../../static/arrow.scss'
import './../../static/icon.scss'


const canvasOptions = {}
export default {
   props: ['searchVals'],
	data() {
		return {
      canvas: null,
      imgUrl:'',
      dialogVisible:false,
      fileList:[],
      srcList:[],
      timer:'',
      countNum:0,
      count:{},
			Tools,
			contextmenu: {
				left: null,
        top: null,
        bottom: null
			},
      props: {
        node: null, // 节点
        line: null, // 连线
        nodes: null,
        multi: false, // 多个对象
        expand: false,
        locked: false,
        grid: true
      },
			canvasData: {}, // 画布数据
			TopologyData: {
        grid: true, // 背景网格
        locked: false, // 画布锁定
        gridSize: 10,
        rule: false
      },
      form:{},
      forms:{
        p1:{},
      },
      shownull:'',
      searchVal:0
		}
	},
	created () {
		// 注册图形库
		canvasRegister()
	},
	methods: {
    getapp(){
      this.searchVal = this.searchVals
      this.axios.StuCprojectgets5({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    this.srcList = this.forms.p1.values
                    var arr = []
                    arr = this.forms.p1.values.filter(el=>el.userId == this.$store.state.user_id)
                    if(arr.length==0){
                      this.shownull = true
                    }else{
                      this.shownull = false
                    }
                    var str = this.forms.resource
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
    },
        // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        clearTimeout(this.timeouts)
                        if(this.count.state==1){
                            this.countDown()
                            this.timeoutss = setTimeout(this.down,5000)
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                    // this.$message.error(res.data.message)
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
                // setTimeout(this.down,5000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        // 提交
        submit(){
            this.axios.submits5({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
    //提交图片
    subnits(e){
      var hasVi = this.canvas.hasView()
      if(hasVi==true){
        var base64Img = this.canvas.toImage(50)
        this.axios.fileimage({
          image:base64Img
        }).then(res=>{
          if(res.data.code==0){
            this.axios.StuCprojectsets5({
              token:this.$store.state.token,
              id:this.$store.state.cla_id,
              node:'p1',
              value:res.data.data
            }).then(res=>{
              if(res.data.code==0){
                this.getapp()
              }else{
                  this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{

        })
      }else{
        this.$message.error("草图不能为空")
      }
    },
    // 重新绘制
    emptys(){
      this.$confirm('重新绘制将会删除当前图片', '确定重新绘制', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal:false
      }).then(() => {
        this.axios.StuCprojectdels5({
          id:this.$store.state.cla_id,
          token:this.$store.state.token,
          node:'p1',
          value:this.forms.p1.value
        }).then(res=>{
          if(res.data.code==0){
            this.$router.go(0)
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{

        })
      }).catch(() => {
          this.$message({
              type: 'info',
              message: '已取消'
          });          
      });
    },
    handsuccessForm(res,file,fileList){
      this.axios.StuCprojectsets5({
        token:this.$store.state.token,
        id:this.$store.state.cla_id,
        node:'p1',
        value:res.data
      }).then(res=>{
        if(res.data.code==0){
          this.getapp()
        }else{
          this.$message.error(res.data.message)
        }
      }).catch(err=>{

      })
    },
    //验证类型
      beforeAvatarUpload(file) {
            const isimgtype = file.type;
            if (isimgtype === "image/jpg" || isimgtype === "image/png" || isimgtype === "image/jpeg") {
            } else {
                this.$message.error("请上传jpg/png/jpeg格式的图片");
                return false;
            }
      },
      handleClose(done){

      },
      dk_Dt(val){
        this.imgUrl = val.value
        this.dialogVisible = true
      },
      closei(){
        this.dialogVisible = false
      },
		init () {
			// 创建画布
			// 第一个参数'topo-canvas'表示canvas的dom元素id；第二个参数{}表示画布选项，这里表示全部使用默认值。
      canvasOptions.on = this.onMessage
      this.canvas = new Topology('topology', canvasOptions)
      this.canvas.data.grid = true
      this.canvas.data.rule = true
      this.canvas.render(this.TopologyData)
		},
		onDrag (event, node) {
			// 解决浏览器手势插件命名冲突
			event.dataTransfer.setData('Topology', JSON.stringify(node.data))
		},
		onContextMenu(event) {
      event.preventDefault()
      event.stopPropagation()
      if (event.clientY + 360 < document.body.clientHeight) {
        this.contextmenu = {
          left: event.clientX + 'px',
          top: event.clientY + 'px'
        }
      } else {
        this.contextmenu = {
          left: event.clientX + 'px',
          bottom: document.body.clientHeight - event.clientY + 'px'
        }
      }
    },
    // 批量操作时是否画布锁定
    getLocked(data) {
      let locked = true
      if (data.nodes && data.nodes.length) {
        for (const item of data.nodes) {
          if (!item.locked) {
            locked = false
            break
          }
        }
      }
      if (locked && data.lines) {
        for (const item of data.lines) {
          if (!item.locked) {
            locked = false
            break
          }
        }
      }
      return locked
    },
    onMessage(event, data) {
      // 右侧输入框编辑状态时点击编辑区域其他元素，onMessage执行后才执行onUpdateProps方法，通过setTimeout让onUpdateProps先执行
      setTimeout(() => {
        switch (event) {
          case 'node':
          case 'addNode':
            this.props = {
              node: data,
              line: null,
              multi: false,
              expand: this.props.expand,
              nodes: null,
              locked: this.TopologyData.locked
            }
            break
          case 'line':
          case 'addLine':
            data.fromArrowColor = data.fromArrowColor ? data.fromArrowColor : '#222'
            data.toArrowColor = data.toArrowColor ? data.toArrowColor : '#222'
            this.props = {
              node: null,
              line: data,
              multi: false,
              nodes: null,
              locked: this.TopologyData.locked
            }
            break
          case 'multi':
            this.props = {
              node: null,
              line: null,
              multi: true,
              nodes: data.length > 1 ? data : null,
              locked: this.getLocked({ nodes: data })
            }
            break
          case 'space':
            this.props = {
              node: null,
              line: null,
              multi: false,
              nodes: null,
              locked: this.TopologyData.locked
            }
            break
          case 'moveOut':
            break
          case 'moveNodes':
          case 'resizeNodes':
            if (data.length > 1) {
              this.props = {
                node: null,
                line: null,
                multi: true,
                nodes: data,
                locked: this.getLocked({ nodes: data })
              }
            } else {
              this.props = {
                node: data[0],
                line: null,
                multi: false,
                nodes: null,
                locked: this.TopologyData.locked
              }
            }
            break
          case 'resize':
          case 'scale':
          case 'locked':
            if (this.canvas && this.canvas.data) {
              this.canvasData = {
                scale: this.canvas.data.scale || 1, // 当前图文缩放比例： 0 - 1
                lineName: this.canvas.data.lineName, // 当前图文默认连线类型
                fromArrowType: this.canvas.data.fromArrowType, // 默认连线起点箭头
                toArrowType: this.canvas.data.toArrowType, // 连线终点默认箭头
                fromArrowlockedType: this.canvas.data.locked // 0-未锁定，可任意编辑，1-只读模式，允许选中，2-禁止鼠标交互，无法做任何操作。纯静态画面模式
              }
            }
            break
        }
      }, 0)
    }
	},
  beforeDestroy () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        clearInterval(that.scont);
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearInterval(that.scont);
        clearTimeout(that.timeoutss)
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
	mounted() {
    this.getapp()
    this.$nextTick(()=>{
      setTimeout(() => {
          this.init()
      }, 500)
    })
    this.down()
      let that = this;
        that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },5000)
	}
}
</script>
<style scoped>
@import './../../assets/css/student/Sketchtodescribe.css';
</style>
<style>
.sket_div .el-button{
  position: absolute;
  top: 20px;
  left: 20px;
}
.sket_div_ul .el-image{
  width: 100%;
  height: calc(100% - 30px);
}
.ipload_div .el-button--mini{
  font-size: 14px;
  margin-right: 25px;
}
.full {
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden !important;
  background: #fff;
  height: 100%;
}
.Sketchtodescribe .topology-scroll{
  display: none;
}
.Sketchtodescribe .topology-scroll.v{
  display: none;
}
/* 弹窗 */
.caotu_popup .el-dialog__footer{
    text-align: center;
}
.caotu_popup .el-form{
    text-align: center;
}
.caotu_popup .el-dialog__header {
   padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.caotu_popup .el-dialog__body{
    background-color: #fff;
}
.caotu_popup .el-dialog__title {
  font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.caotu_popup .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
.caotu_popup .el-dialog__headerbtn {
  top: 15px;
}
</style>